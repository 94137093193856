var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "제안작성" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          !_vm.param.isApprDisabled && _vm.isUser
                            ? _c("c-appr-btn", {
                                ref: "appr-btn",
                                attrs: {
                                  name: "danger-journal-appr-btn",
                                  editable: _vm.editable,
                                  approvalInfo: _vm.approvalInfo,
                                },
                                on: {
                                  beforeApprAction: _vm.saveDataAppr,
                                  callbackApprAction: _vm.approvalCallback,
                                  requestAfterAction: _vm.requestAfterAction,
                                },
                              })
                            : _vm._e(),
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.editable &&
                                  Boolean(_vm.param.saiSugSuggestionId) &&
                                  !_vm.disabled &&
                                  !_vm.isUser,
                                expression:
                                  "editable && Boolean(param.saiSugSuggestionId) && !disabled && !isUser",
                              },
                            ],
                            attrs: {
                              url: _vm.completeUrl,
                              isSubmit: _vm.isComplete,
                              param: _vm.param.suggestion,
                              mappingType: "PUT",
                              label: "완료요청",
                              icon: "check",
                            },
                            on: {
                              beforeAction: _vm.completeData,
                              btnCallback: _vm.completeCallback,
                            },
                          }),
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.editable &&
                                  Boolean(_vm.param.saiSugSuggestionId) &&
                                  !_vm.disabled &&
                                  !_vm.param.isApprDisabled,
                                expression:
                                  "editable && Boolean(param.saiSugSuggestionId) && !disabled && !param.isApprDisabled",
                              },
                            ],
                            attrs: {
                              label: "LBLREMOVE",
                              editable: _vm.editable,
                              icon: "delete_forever",
                            },
                            on: { btnClicked: _vm.removeData },
                          }),
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.editable && !_vm.disabled,
                                expression: "editable && !disabled",
                              },
                            ],
                            attrs: {
                              url: _vm.saveUrl,
                              isSubmit: _vm.isSave,
                              param: _vm.param.suggestion,
                              mappingType: _vm.mappingType,
                              label: "LBLSAVE",
                              icon: "save",
                            },
                            on: {
                              beforeAction: _vm.saveData,
                              btnCallback: _vm.saveCallback,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "LBLTITLE",
                            name: "suggestionTitle",
                          },
                          model: {
                            value: _vm.param.suggestion.suggestionTitle,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.param.suggestion,
                                "suggestionTitle",
                                $$v
                              )
                            },
                            expression: "param.suggestion.suggestionTitle",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: true,
                            name: "suggestionNo",
                            label: "제안번호",
                          },
                          model: {
                            value: _vm.param.suggestion.suggestionNo,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.param.suggestion,
                                "suggestionNo",
                                $$v
                              )
                            },
                            expression: "param.suggestion.suggestionNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-select", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            type: "edit",
                            codeGroupCd: "SAI_SUGGEST_DIVISION_SUGGESTION_CD",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "suggestDivisionTypeCd",
                            label: "제안구분",
                          },
                          model: {
                            value: _vm.param.suggestion.suggestDivisionTypeCd,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.param.suggestion,
                                "suggestDivisionTypeCd",
                                $$v
                              )
                            },
                            expression:
                              "param.suggestion.suggestDivisionTypeCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-select", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            type: "edit",
                            codeGroupCd: "SAI_SUGGEST_CATEGORY_SUGGESTION_CD",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "suggestCategoryTypeCd",
                            label: "제안유형",
                          },
                          model: {
                            value: _vm.param.suggestion.suggestCategoryTypeCd,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.param.suggestion,
                                "suggestCategoryTypeCd",
                                $$v
                              )
                            },
                            expression:
                              "param.suggestion.suggestCategoryTypeCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "LBL0010183",
                            name: "suggestionDate",
                          },
                          model: {
                            value: _vm.param.suggestion.suggestionDate,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.param.suggestion,
                                "suggestionDate",
                                $$v
                              )
                            },
                            expression: "param.suggestion.suggestionDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-location", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            data: _vm.param.suggestion,
                            etcInput: "locationEtc",
                            label: "위치",
                            name: "suggestionLocation",
                          },
                          model: {
                            value: _vm.param.suggestion.suggestionLocation,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.param.suggestion,
                                "suggestionLocation",
                                $$v
                              )
                            },
                            expression: "param.suggestion.suggestionLocation",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _vm.param.suggestion.regUserId.indexOf("U") > -1 ||
                        (_vm.isUser && !_vm.param.saiSugSuggestionId)
                          ? _c("c-field", {
                              attrs: {
                                required: "",
                                editable: _vm.editable,
                                disabled: _vm.disabled,
                                data: _vm.param.suggestion,
                                label: "제안자",
                                type: "dept_user",
                                name: "regUserId",
                              },
                              model: {
                                value: _vm.param.suggestion.regUserId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.param.suggestion,
                                    "regUserId",
                                    $$v
                                  )
                                },
                                expression: "param.suggestion.regUserId",
                              },
                            })
                          : _c("c-vendor", {
                              attrs: {
                                required: "",
                                editable: _vm.editable,
                                disabled: true,
                                data: _vm.param.suggestion,
                                label: "제안업체",
                                name: "vendorCd",
                              },
                              model: {
                                value: _vm.param.suggestion.vendorCd,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.param.suggestion,
                                    "vendorCd",
                                    $$v
                                  )
                                },
                                expression: "param.suggestion.vendorCd",
                              },
                            }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-plant", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            type: "edit",
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.param.suggestion.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.param.suggestion, "plantCd", $$v)
                            },
                            expression: "param.suggestion.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-dept", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "개선실행부서",
                            name: "execDeptCd",
                          },
                          model: {
                            value: _vm.param.suggestion.execDeptCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.param.suggestion, "execDeptCd", $$v)
                            },
                            expression: "param.suggestion.execDeptCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            rows: 4,
                            label: "현황",
                            name: "suggestionStatus",
                          },
                          model: {
                            value: _vm.param.suggestion.suggestionStatus,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.param.suggestion,
                                "suggestionStatus",
                                $$v
                              )
                            },
                            expression: "param.suggestion.suggestionStatus",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            rows: 4,
                            label: "문제점",
                            name: "suggestionProblem",
                          },
                          model: {
                            value: _vm.param.suggestion.suggestionProblem,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.param.suggestion,
                                "suggestionProblem",
                                $$v
                              )
                            },
                            expression: "param.suggestion.suggestionProblem",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            rows: 4,
                            label: "개선방안",
                            name: "suggestionSuggest",
                          },
                          model: {
                            value: _vm.param.suggestion.suggestionSuggest,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.param.suggestion,
                                "suggestionSuggest",
                                $$v
                              )
                            },
                            expression: "param.suggestion.suggestionSuggest",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            rows: 4,
                            label: "LBL0010188",
                            name: "suggestionBenefit",
                          },
                          model: {
                            value: _vm.param.suggestion.suggestionBenefit,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.param.suggestion,
                                "suggestionBenefit",
                                $$v
                              )
                            },
                            expression: "param.suggestion.suggestionBenefit",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-sm-12 col-md-12 col-lg-12 col-xl-12",
                      },
                      [
                        _c("c-upload", {
                          attrs: {
                            attachInfo: _vm.param.attachInfo,
                            editable: _vm.editable && !_vm.disabled,
                            label: "첨부파일",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }