<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <c-card title="제안작성" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline >
              <!-- [S]결재관련 버튼 -->
              <!-- sysApprovalRequestId: 상세조회시 관련 결재요청일련번호 확인 -->
              <!-- popupParam.isApprContent: 결재요청/결재처리 팝업의 component에 해당 페이지 호출시 true -->
              <!-- approvalStatusCd: 결재요청건에 대한 상태코드 -->
              <!-- popupParam.approvalDocType: TODO(결재할문서에서 상세페이지호출시) -->
              <c-appr-btn 
                v-if="!param.isApprDisabled && isUser"
                ref="appr-btn"
                name="danger-journal-appr-btn"
                :editable="editable"
                :approvalInfo="approvalInfo"
                @beforeApprAction="saveDataAppr"
                @callbackApprAction="approvalCallback"
                @requestAfterAction="requestAfterAction"
              />
              <c-btn
                v-show="editable && Boolean(param.saiSugSuggestionId) && !disabled && !isUser"
                :url="completeUrl"
                :isSubmit="isComplete"
                :param="param.suggestion"
                mappingType="PUT"
                label="완료요청"
                icon="check"
                @beforeAction="completeData"
                @btnCallback="completeCallback" />
              <!-- <c-btn
                v-show="editable && Boolean(param.saiSugSuggestionId) && !disabled && !isUser"
                :url="apprRequestUrl"
                :isSubmit="isApprRequest"
                :param="param.suggestion"
                mappingType="PUT"
                label="승인요청"
                icon="check"
                @beforeAction="apprRequestData"
                @btnCallback="apprRequestCallback" /> -->
              <!--삭제-->
              <c-btn 
                v-show="editable && Boolean(param.saiSugSuggestionId) && !disabled && !param.isApprDisabled" 
                label="LBLREMOVE" 
                :editable="editable" 
                icon="delete_forever" 
                @btnClicked="removeData" />
              <!--저장-->
              <c-btn
                v-show="editable && !disabled"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="param.suggestion"
                :mappingType="mappingType"
                label="LBLSAVE"
                icon="save"
                @beforeAction="saveData"
                @btnCallback="saveCallback" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <!--제목-->
              <c-text
                required
                :editable="editable"
                :disabled="disabled"
                label="LBLTITLE"
                name="suggestionTitle"
                v-model="param.suggestion.suggestionTitle">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-text 
                :editable="editable" 
                :disabled="true"
                name="suggestionNo" 
                label="제안번호"
                v-model="param.suggestion.suggestionNo"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-select
                :editable="editable"
                :disabled="disabled"
                type="edit"
                codeGroupCd="SAI_SUGGEST_DIVISION_SUGGESTION_CD"
                itemText="codeName"
                itemValue="code"
                name="suggestDivisionTypeCd"
                label="제안구분"
                v-model="param.suggestion.suggestDivisionTypeCd"
              >
              </c-select>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-select
                :editable="editable"
                :disabled="disabled"
                type="edit"
                codeGroupCd="SAI_SUGGEST_CATEGORY_SUGGESTION_CD"
                itemText="codeName"
                itemValue="code"
                name="suggestCategoryTypeCd"
                label="제안유형"
                v-model="param.suggestion.suggestCategoryTypeCd"
              >
              </c-select>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <!--의견게시일-->
              <c-datepicker
                required
                :editable="editable"
                :disabled="disabled"
                label="LBL0010183"
                name="suggestionDate"
                v-model="param.suggestion.suggestionDate">
              </c-datepicker>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <c-location
                :editable="editable"
                :disabled="disabled"
                :data="param.suggestion"
                etcInput="locationEtc"
                label="위치"
                name="suggestionLocation"
                v-model="param.suggestion.suggestionLocation">
              </c-location>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-field
                v-if="(param.suggestion.regUserId.indexOf('U') > -1) || (isUser && !param.saiSugSuggestionId)"
                required
                :editable="editable"
                :disabled="disabled"
                :data="param.suggestion"
                label="제안자"
                type="dept_user"
                name="regUserId"
                v-model="param.suggestion.regUserId">
              </c-field>
              <c-vendor
                v-else
                required
                :editable="editable"
                :disabled="true"
                :data="param.suggestion"
                label="제안업체"
                name="vendorCd"
                v-model="param.suggestion.vendorCd"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-plant
                required
                :editable="editable"
                :disabled="disabled"
                type="edit"
                name="plantCd"
                v-model="param.suggestion.plantCd">
              </c-plant>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-dept
                :editable="editable"
                :disabled="disabled"
                label="개선실행부서"
                name="execDeptCd"
                v-model="param.suggestion.execDeptCd">
              </c-dept>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-textarea
                :editable="editable"
                :disabled="disabled"
                :rows="4"
                label="현황"
                name="suggestionStatus"
                v-model="param.suggestion.suggestionStatus">
              </c-textarea>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <!--문제점-->
              <c-textarea
                :editable="editable"
                :disabled="disabled"
                :rows="4"
                label="문제점"
                name="suggestionProblem"
                v-model="param.suggestion.suggestionProblem">
              </c-textarea>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-textarea
                :editable="editable"
                :disabled="disabled"
                :rows="4"
                label="개선방안"
                name="suggestionSuggest"
                v-model="param.suggestion.suggestionSuggest">
              </c-textarea>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <!--기대효과-->
              <c-textarea
                :editable="editable"
                :disabled="disabled"
                :rows="4"
                label="LBL0010188"
                name="suggestionBenefit"
                v-model="param.suggestion.suggestionBenefit">
              </c-textarea>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <!--첨부파일-->
              <c-upload 
                :attachInfo="param.attachInfo"
                :editable="editable && !disabled"
                label="첨부파일">
              </c-upload>
            </div>
          </template>
        </c-card>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </q-form>
  </div>
</template>

<script>
import {
  getLoginUser,
} from '@/utils/auth'; // getToken from cookie
import { uid } from 'quasar';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'suggestionInfo',
  props: {
    param: {
      type: Object,
      default: () => ({
        saiSugSuggestionId: '',
        suggestion: {
          saiSugSuggestionId: '',
          suggestionStepCd: '',
          suggestionNo: '',
          plantCd: '',
          regDeptCd: '',
          execDeptCd: '',
          suggestionDate: '',
          suggestionStatus: '',
          suggestionTitle: '',
          suggestionProblem: '',
          suggestDivisionTypeCd: null,
          suggestCategoryTypeCd: null,
          suggestionSuggest: '',
          suggestionBenefit: '',
          suggestionImprove: '',
          suggestionLocation: '',
          locationEtc: '',
          suggestionFirstAuditCd: '',
          suggestionSecondAuditCd: '',
          suggestionFirstAuditOpinion: '',
          suggestionSecondAuditOpinion: '',
          suggestionTypeEffect: '',
          suggestionIntangibleEffect: '',
          bountyFirst: '',
          bountySecond: '',
          sysApprovalRequestId: '',
          approvalStatusCd: '',
          vendorCd: '',
          approvalRequestFlag: '',
          regUserId: '',
          chgUserId: '',
        },
        attachInfo: {
          isSubmit: '',
          taskClassCd: 'SOB_BOARD',
          taskKey: '',
        },
        isApprDisabled: false,
      }),
    },
  },
  data() {
    return {
      editable: true,
      mappingType: 'POST',
      saveUrl: '',
      apprRequestUrl: '',
      deleteUrl: '',
      completeUrl: '',
      isSave: false,
      isApprRequest: false,
      isComplete: false,
      isApproval: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    disabled() {
      /**
       * 1. 초기등록상태가 아니고 SSSSC00001 아닐때
       * 2. 결재중인경우
       */
      return Boolean(this.param.saiSugSuggestionId)
        && (this.param.suggestion.suggestionStepCd !== 'SSSSC00001')
        // 결재중인 경우 비활성화
        || (this.param.suggestion.approvalStatusCd === 'ASC0000001' && !this.param.isApprDisabled)
        || (!this.isUser && this.param.suggestion.approvalRequestFlag === 'Y')
    },
    approvalInfo() {
      return {
        sysApprovalRequestId: this.param.suggestion.sysApprovalRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.param.suggestion.approvalStatusCd, 
        apprEditable: !this.disabled && this.param.suggestion.saiSugSuggestionId && !this.param.isApprDisabled, // 결재버튼 활성화 기준
        param: this.param.suggestion, // 결재 param
        approvalUrl: transactionConfig.sai.sug.suggestion.update.url, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000025', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          saiSugSuggestionId: this.param.saiSugSuggestionId,
          suggestionStepCd: this.param.suggestion.suggestionStepCd,
          isApprContent: true
        },
        approvalRequestName: this.param.suggestion.suggestionTitle + '_' + this.param.suggestion.suggestionDate, // 결재요청명 (문서 title + date)
        approvalConnId: this.param.saiSugSuggestionId, // 결재연결용 업무일련번호 (문서 key)
      }
    },
    isUser() {
      return getLoginUser() === 'U'
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.sai.sug.suggestion.insert.url;
      this.apprRequestUrl = transactionConfig.sai.sug.suggestion.apprRequest.url;
      this.deleteUrl = transactionConfig.sai.sug.suggestion.delete.url;
      this.completeUrl = transactionConfig.sai.sug.suggestion.complete.url;
      // code setting
      // list setting 
    },
    saveData() {
      if (this.param.saiSugSuggestionId) {
        this.saveUrl = transactionConfig.sai.sug.suggestion.update.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sai.sug.suggestion.insert.url;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',    // 확인
            message: 'MSGSAVE', // 저장하시겠습니까?     // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.param.suggestion.regUserId = this.$store.getters.user.userId;
              this.param.suggestion.chgUserId = this.$store.getters.user.userId;
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (!this.param.saiSugSuggestionId) {
        this.$set(this.param, 'saiSugSuggestionId', result.data)
        this.$set(this.param.attachInfo, 'taskKey', result.data)
        this.$set(this.param.attachInfo, 'isSubmit', uid())
      }
      this.$emit('emitStep', {
        name: 'setSaiSugSuggestionId',
        param: this.param.saiSugSuggestionId,
      })
    },
    // apprRequestData() {
    //   this.$refs['editForm'].validate().then(_result => {
    //     if (_result) {
    //       window.getApp.$emit('CONFIRM', {
    //         title: 'LBLCONFIRM',    // 확인
    //         message: '승인요청하시겠습니까?', // 저장하시겠습니까?
    //         // TODO : 필요시 추가하세요.
    //         type: 'info', // success / info / warning / error
    //         // 확인 callback 함수
    //         confirmCallback: () => {
    //           this.param.suggestion.regUserId = this.$store.getters.user.userId;
    //           this.param.suggestion.chgUserId = this.$store.getters.user.userId;

    //           this.isApprRequest = !this.isApprRequest;
    //         },
    //         // 취소 callback 함수
    //         cancelCallback: () => {
    //         },
    //       });
    //     } else {
    //       window.getApp.$emit('APP_VALID_ERROR');
    //     }
    //   });
    // },
    // apprRequestCallback() {
    //   window.getApp.$emit('APP_REQUEST_SUCCESS');
    //   this.$emit('emitStep', {
    //     name: 'getDetail',
    //     param: { 
    //       saiSugSuggestionId: this.param.saiSugSuggestionId
    //     },
    //   })
    // },
    removeData() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',  // 확인
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.param.saiSugSuggestionId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('emitStep', {
              name: 'closePopup',
              param: {},
            })
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    /* eslint-disable no-unused-vars */
    saveDataAppr() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        // 결재요청 하기 전 입력된 값을 저장합니다.\n\r 진행하시겠습니까?
        message: 'MSG0000430',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.param.suggestion.chgUserId = this.$store.getters.user.userId
          this.isApproval = !this.isApproval
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    // [S] 결재관련 
    approvalCallback() { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
    requestAfterAction() {
      this.$emit('emitStep', {
        name: 'getDetail',
        param: {},
      })
    },
    completeData() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '완료하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.param.suggestion.chgUserId = this.$store.getters.user.userId
              this.isComplete = !this.isComplete
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('emitStep', {
        name: 'getDetail',
        param: { 
          saiSugSuggestionId: this.param.saiSugSuggestionId
        },
      })
    },
  }
};
</script>
